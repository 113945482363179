import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
const httpOptions = {
  headers: new HttpHeaders({    
    'Authorization': 'Token token=9bJDDcx-zNhW7HweRH8V-U6_CRZBd3Mh3q7RZZuU43k',
    'Content-Type':  'application/json'
  })
};

const admitadHttpHeader = {
  headers: new HttpHeaders({    
    'Authorization': 'Bearer 893a0a11bc256e6d099d',
    'Content-Type': 'application/json'
  })
};

const cuelinksHeader = {
  headers: new HttpHeaders({    
    'Authorization': 'Token token=9bJDDcx-zNhW7HweRH8V-U6_CRZBd3Mh3q7RZZuU43k',
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(private http: HttpClient) { }
  serverHost:string = "https://paytmzone.com/ptz_api";
  //serverHost:string = "http://localhost/ptz_api";
  //admitad
  admitadAccessData:Object;
  admitadAccessUrl:string = this.serverHost+"/admitad_api/access.php";
  categoryUrl:string = this.serverHost+"/admitad_api/getCategories.php";
  categoryDetailsUrl:string = this.serverHost+"/admitad_api/getCategoryDetails.php";
  //categoryUrl:string = "https://api.admitad.com/coupons/categories/?language=en";

  //cuelinks
  campaignUrl:string = this.serverHost+"/cuelinks_api/allCampaign.php";
  offersUrl:string = this.serverHost+"/cuelinks_api/offerByCampaignId.php";

  //strings
  selectedCategory:string = sessionStorage.getItem("selectedCategory");

  getCampaignList(){    
    return this.http.get(this.campaignUrl);
  }

  getadmitAdAccess(){
    return this.http.get(this.admitadAccessUrl);    
  }

  getCategories(){
    return this.http.get(this.categoryUrl+"?token="+sessionStorage.getItem('token'));
  }

  getCategoryDetails(id){
    return this.http.get(this.categoryDetailsUrl+"?token="+sessionStorage.getItem('token')+"&id="+id);
  }

  getSearchResult(query){
    return this.http.get(this.categoryDetailsUrl+"?token="+sessionStorage.getItem('token')+"&q="+query);
  }

  getStoreSearchResult(query){
    return this.http.get(this.campaignUrl+"?q="+query);
  }

  getOffers(){
    return this.http.get(this.offersUrl);
  }

  getOffersByCampaignId(id){
    return this.http.get(this.offersUrl+"?id="+id);
  }

}
