<footer>
  <p class="footer-slogan">" All <b>Offers</b> under One Roof !! "</p>
  <div class="container">
      <!-- <button type="button" class="btn " data-toggle="collapse" data-target="#demo">Contact Us</button>
      <div id="demo" class="collapse">
          <a href="http://reverziel.com/contact-us"> <b>ReverZiel Infotech</b> !! Your Success is our Buisness </a>
      <div> +91 9112 113 614 </div>
      </div> -->
      <!-- Trigger the modal with a button -->
      <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Contact Us</button>

      <!-- Modal -->
      <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog modal-sm">
          <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title">Contacts</h4>
          </div>
          <div class="modal-body">
              <a href="http://reverziel.com/contact-us"> <b>ReverZiel Infotech</b> !! Your Success is our Buisness </a>
              <div> +91 9112 113 614 </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Ok</button>
          </div>
          </div>
      </div>
      </div>
  </div>
</footer>





<div class="footer-bottom">
  <i>(©) Copyright 2019 PaytmZone. All Rights Reserved.</i>
</div>
