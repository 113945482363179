<app-nav style="margin-bottom: 100px;"></app-nav>
<router-outlet></router-outlet>
<!-- Goole Adsence -->
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({
          google_ad_client: "ca-pub-4564515161147749",
          enable_page_level_ads: true
     });
</script>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- paytmzone-1 -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-4564515161147749"
     data-ad-slot="2071438499"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
<app-footer></app-footer>
<!-- <ngx-spinner
bdOpacity = 0.7
bdColor = ""
size = "medium"
color = "#f7bf3d"
type = "square-jelly-box"
[fullScreen] = "true"
>
<p style="color: white" ></p>
</ngx-spinner> -->
<!-- <ngx-spinner
bdOpacity = 0.9
bdColor = "#333"
size = "medium"
color = "#fff"
type = "ball-spin"
[fullScreen] = "true"
>
<p style="color: white" > Loading... </p>
</ngx-spinner> -->
