import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { OffersComponent } from './offers/offers.component';
import { CategoryComponent } from './category/category.component';
import { SearchResultComponent } from './search-result/search-result.component';

const routes: Routes = [
  { 
    path: '',
    component: HomeComponent,    
    runGuardsAndResolvers: 'always'
  },
  { 
    path: 'category/:id',
    component: CategoryComponent,
    runGuardsAndResolvers: 'always'
    
  },
  { 
    path: 'search/:q',
    component: SearchResultComponent,
    runGuardsAndResolvers: 'always'    
  },
  { 
    path: 'offers',
    component: OffersComponent,    
    runGuardsAndResolvers: 'always'
  },
  { 
    path: 'about',
    component: AboutComponent,
    runGuardsAndResolvers: 'always'
  },
  { 
    path: 'contact',
    component: ContactComponent,
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
