 <div class="nav-bottom1">
    <nav id="global-nav" class="navbar navbar-inverse navbar-fixed-top pz-top">
      <div class="container-fluid">
        <div class="navbar-header">

          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="">
          <a class="navbar-brand img-responsive" href="#"><img src="https://paytmzone.com/ptz_api/logo.png" class="logo-mobi img-responsive ptz_logo" alt="PaytmZone"></a>
          </div>
        </div>
        <ul class="nav navbar-nav">
          <li class="active mob-nav">
            <form class="navbar-form navbar-right pull-right">
              <div class="input-group">
                  <input type="text" class="form-control top-search" placeholder="Search anything to see related Deals & Coupons" name="search" [(ngModel)]="searchTxt" (keyup.enter)="searchAll()">
                  <div class="input-group-btn">
                      <button class="btn btn-primary btn-theme" type="button" (click)="searchAll()">
                      <i class="glyphicon glyphicon-search"></i>
                      </button>
                  </div>
              </div>
            </form>
          </li>
        </ul>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav outerNav">
              <li><a href="#">Home</a></li>
              <li><a href="offers">Today's Deals</a></li>
              <li class="dropdown"><a class="dropdown-toggle" data-toggle="dropdown" href="#">Categories <span class="caret"></span></a>
                <ul class="dropdown-menu">
                  <li class="category" *ngFor="let catList of categoryList">
                      <a routerLink="/category/{{catList.id}}" (click)="setCategoryName(catList)">{{catList.name}}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="active pc-nav">
                <form class="navbar-form navbar-right pull-right">
                  <div class="input-group">
                      <input type="text" class="form-control top-search" placeholder="Search anything to see related Deals & Coupons" name="search" [(ngModel)]="searchTxt" (keyup.enter)="searchAll()">
                      <div class="input-group-btn">
                          <button class="btn btn-primary btn-theme" type="button" (click)="searchAll()">
                          <i class="glyphicon glyphicon-search"></i>
                          </button>
                      </div>
                  </div>
                </form>
              </li>
            </ul>
          </div>
      </div>
    </nav>
</div>
