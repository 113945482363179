import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  appTitle = 'PayTm Zone';
  searchTxt: string;
  categoryList: Object;

  constructor(private dataService: DataService, private router: Router) {

  }

  ngOnInit() {
    // admitad access
    this.dataService.getadmitAdAccess().subscribe(data => {
      sessionStorage.setItem('token', data['access_token']);
      sessionStorage.setItem('rtoken', data['refresh_token']);
      this.getCategory();
    });

    // tslint:disable-next-line: deprecation
    $(document).ready(function() {
      $('.carousel').on('touchstart', function(event) {
        const xClick = event.originalEvent.touches[0].pageX;
        // tslint:disable-next-line: no-shadowed-variable
        $(this).one('touchmove', function(event) {
          const xMove = event.originalEvent.touches[0].pageX;
          if ( Math.floor(xClick - xMove) > 5 ) {
              //$(this).carousel('next'); 
          } else if ( Math.floor(xClick - xMove) < -5 ) {
              //$(this).carousel('prev');
          }
        });
        $('.carousel').on('touchend', function() {
            $(this).off('touchmove');
        });
      });
    });

  }

  setCategoryName(category: { [x: string]: string; }): void {
    // console.log(category);
    this.dataService.selectedCategory = category['name'];
    sessionStorage.setItem('selectedCategory', category['name']);

  }


  getCategory(): void {
    this.dataService.getCategories().subscribe(response => {
      this.categoryList = response['results'];
      // console.log('Category data===>', this.categoryList);
    });
  }

  searchAll(): void {
    if (this.searchTxt) {
      this.router.navigateByUrl('/search/' + this.searchTxt);
    } else {
      return;
    }
    // console.log('Search', this.searchTxt);

  }



}
