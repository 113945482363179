<div class="container-fluid text-center categoryHeader">
    <h3><b> Search Result for "{{searchQueryParam}}" </b></h3> 
    <p> Stores, Deals & Coupons </p> 
</div>
<div class="container">
  <div class="headerTxt">
    <h2>Stores</h2>
  </div>
  <div class="row" *ngIf="searchStoreResultList">
    <div class="col-md-2 campaign-block" *ngFor="let list of searchStoreResultList.campaigns">
        <div class="thumbnail">
          <a href="https://linksredirect.com/?pub_id=39075CL35269&source=linkkit&url={{list.url}}" target="_blank">
            <img [src]="list.image" class="img-rounded" [alt]="list.name">
            <div class="caption text-center">
              <p>{{list.name}}</p>
            </div>
          </a>
        </div>
      </div>  
  </div>
</div>


<!-- Coupon List -->
<div class="container">
  <div class="headerTxt">
    <h2>Deals & Coupons</h2>
  </div>
  <div class="row" *ngIf="searchResultList">
    <div class="col-md-3" *ngFor="let list of searchResultList">
    <div class="thumbnail couponListBlock">
      <!-- campaign / site_url -->  
      <a [href]="list.goto_link" target="_blank">
      <!-- categories / image -->
      <img [src]="list.image" class="marchantLogo" [alt]="list.species">
      </a>
      <div class="caption">
        <!-- categories / short_name -->
        <div class="well well-sm">
          {{list.short_name}}
        </div>			
      </div>
      <!-- categories / frameset_link -->
      <div class="acenter">
      <a *ngIf="list.promocode=='NOT REQUIRED'" target="_blank" [href]="list.goto_link" class="btn btn-info">
          <span class="glyphicon glyphicon-duplicate" style="margin-right:10px"></span> See Deal
      </a>  
      <button *ngIf="list.promocode!='NOT REQUIRED'" (click)="onCouponSelected(list)" type="button" class="btn btn-warning" data-toggle="modal" data-target="#couponModal">Show Coupon Code</button>
      </div>        
      <button type="button" class="btn btn-link btn-block">View all offers</button>  
    </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div id="couponModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content couponTxtModal">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Use the coupon code below</h4>
      </div>
      <div class="modal-body" *ngIf="couponSelected">
        <img [src]="couponSelected.image" class="marchantLogo" [alt]="couponSelected.species">
        <h3>{{couponSelected.short_name}}</h3>      
        <div class="row main">
          <div class="col-md-6">
              <input type="text" id="promocode" class="couponTxt" [value]="couponSelected.promocode" readonly>
          </div>
          <div class="col-md-6">
              <button (click)="copyCouponTxt()" type="button" class="btn btn-warning">COPY</button>
          </div>
        </div>  
        
        <h5 *ngIf="couponSelected.discount">Discount: {{couponSelected.discount}}</h5>
        <h5 *ngIf="couponSelected.date_end">Offer Ends On {{couponSelected.date_end | date:"dd/MM/yyyy"}}</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
      