import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../data.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterMerchantTxt: string): any[] {
    if (!items) { return []; }
    if (!filterMerchantTxt) { return items; }
    filterMerchantTxt = filterMerchantTxt.toLowerCase();
    //console.log(items);
    return items.filter( it => {
      return it.name.toLowerCase().includes(filterMerchantTxt);
    });
   }
}



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  campaignList: Object;
  categoryList: Object;
  injectTo: string;
  subID: string;
  subid_block: string;
  filterMerchantTxt:string;
  constructor(private dataService: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.dataService.getCampaignList().subscribe(data => {
      this.campaignList = data;
      //console.log('data===>', this.campaignList);
      this.spinner.hide();
    });
    this.sliderCreator();
  }

  sliderCreator(): void {
    for (let i = 0; i < 12; i++) {
      const actualId = i + 1;
      this.dynamicDesBannerCreator(actualId);
      this.dynamicMobBannerCreator(actualId);
    }
  }

  dynamicDesBannerCreator(param): void {
    /* Optional settings (these lines can be removed): */
    const subID = '';  // - local banner key;
    let injectTo = 'm' + param;  // - #id of html element (ex., "top-banner").
    let subid_block = '';
  /* End settings block */
    // tslint:disable-next-line: triple-equals
    if (injectTo == '') {injectTo = 'admitad_shuffle' + subID + Math.round(Math.random() * 100000000); }
    // tslint:disable-next-line: triple-equals
    if (subID == '') {subid_block = ''; } else { subid_block = 'subid/' + subID + '/'; }
    document.getElementById('testBanner' + param).innerHTML = '<div class="mainBanner" id="' + injectTo + '"></div>';
    const s = document.createElement('script');
    s.type = 'text/javascript'; s.async = true;
    s.src = 'https://ad.admitad.com/shuffle/c806ee32a6/' + subid_block + '?inject_to=' + injectTo;
    const x = document.getElementsByTagName('script')[0];
    console.log(s)
    x.parentNode.insertBefore(s, x);
  }

  dynamicMobBannerCreator(param): void {
    /* Optional settings (these lines can be removed): */
    const subID = '';  // - local banner key;
    let injectTo = 'b' + param;  // - #id of html element (ex., "top-banner").
    let subid_block = '';
  /* End settings block */

    // tslint:disable-next-line: triple-equals
    if (injectTo == '') {injectTo = 'admitad_shuffle' + subID + Math.round(Math.random() * 100000000); }
    // tslint:disable-next-line: triple-equals
    if (subID == '') {subid_block = ''; } else { subid_block = 'subid/' + subID + '/'; }
    document.getElementById('mobBanner' + param).innerHTML = '<div class="mainBanner" id="' + injectTo + '"></div>';
    const s = document.createElement('script');
    s.type = 'text/javascript'; s.async = true;
    s.src = 'https://ad.admitad.com/shuffle/c806ee32a6/' + subid_block + '?inject_to=' + injectTo;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}
