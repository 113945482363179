<div class="offers-mob">
  <div class="container-fluid text-center offersHeader">
      <h3><b> TODAY'S OFFERS & COUPONS </b></h3> 
      <!-- <p> COUPON CODES & OFFERS </p>  -->
  </div>

  <div class="container-fluid">
    <div class="row">
      <!-- stores -->

      <div class="container-fluid col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <input type="text" [(ngModel)]="searchStoreTxt" class="searchStore search-store"(keyup)="searchStoreLive()" placeholder=" &#128269; Search your favourite Store" />
      </div>
      <div class="container-fluid col-lg-4 col-md-4 col-sm-4 col-xs-4 left-content">
        <div class="row" *ngIf="campaignList">              
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 campaign-block" *ngFor="let list of campaignList.campaigns; index as i">
            <div class="thumbnail" tabindex={{i}}  (click)="selectedStore(list)">
              <!-- <a href="https://linksredirect.com/?pub_id=39075CL35269&source=linkkit&url={{list.url}}" target="_blank"> -->
              <a class="storeBox">  
                <div class="img"><img [src]="list.image" class="img-responsive" [alt]="list.name"></div>
                <div class="caption text-center">
                  <p>{{list.name}}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 text-center" *ngIf="!campaignList">
            <h3 style="color: #FDD835">No Store Found</h3>     
          </div>
        </div>
      </div>
      <!-- related deals coupons -->
      <div class="container-fluid col-lg-8 col-md-8 col-sm-8 col-xs-8 right-content" *ngIf="offersList">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 offers-block" *ngFor="let list of offersList.offers">
            <div class="thumbnail">
              <a [href]="list.affiliate_url" target="_blank">
                <img [src]="list.image_url" class="img-responsive" [alt]="list.name">
                <div class="caption text-center">
                  <!-- <p>{{list.campaign}}</p> -->
                  <h5>{{list.title}}</h5>
                </div>
              </a>
            </div>
          </div>  
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngIf="zeroDeals">
          <h3 style="color: #a5a5a5;">No Deals Found on {{selectedStoreObj.name}}</h3>
          <a href="https://linksredirect.com/?pub_id=39075CL35269&source=linkkit&url={{selectedStoreObj.url}}" target="_blank" class="btn btn-info" role="button">Go to Store and Check</a>
        </div>
      </div>
    </div>
  </div>
</div>