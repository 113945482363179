import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../data.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private dataService: DataService, private spinner: NgxSpinnerService) { }

  couponList: Object;
  categoryName:string;
  selectedCategory: Object;
  couponSelected: Object;

  ngOnInit() {
    this.getCategoryDetails();
  }

  getCategoryDetails():void {
    this.route.queryParams.subscribe(queryParams => {
      // do something with the query params
    });
    this.route.params.subscribe(routeParams => {
      this.onSelectCategory(routeParams.id);
    });
  }

  onSelectCategory(id): void {
    this.spinner.show();
    this.dataService.getCategoryDetails(id)
      .subscribe(data => {
        //console.log("CATEGORY DETAILS:", data);
        this.couponList = data["results"];
        this.categoryName = this.dataService.selectedCategory;
        //console.log(this.categoryName);
        this.spinner.hide();
    });
  }

  onCouponSelected(list): void{
    //console.log(list);
    this.couponSelected = list;
    var goto_link = this.couponSelected["goto_link"];
    setTimeout(function(){
      //console.log("URL", goto_link)
      window.open(goto_link, "_blank");
    }, 3000)
  }

  copyCouponTxt(): void{
    var copyText = document.getElementById("promocode") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
    var goto_link = this.couponSelected["goto_link"];
    setTimeout(function(){
      //console.log("URL", goto_link)
      window.open(goto_link, "_blank");
    }, 1000)


  }
}
