<div class="container-fluid home-container">
  <!-- Banner conatiner -->



	<div class="container-fluid des-carousel">
		<div id="myCarousel" class="carousel slide" data-ride="carousel"  data-interval="3500" data-pause="hover">
			<!-- Carousel indicators -->
			<ol class="carousel-indicators">
				<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
				<li data-target="#myCarousel" data-slide-to="1">
				<li data-target="#myCarousel" data-slide-to="2">
			</ol>
			<!-- Carousel items -->
			<div class="carousel-inner">
				<div class="item carousel-item active">
					<div class="row">
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner1"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner2"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner3"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner4"></div></div>
					</div>			
				</div>
				<div class="item carousel-item">
					<div class="row">
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner5"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner6"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner7"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner8"></div></div>
					</div>			
				</div>
				<div class="item carousel-item">
					<div class="row">
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner9"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner10"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner11"></div></div>
						<div class="col-md-3"><div class="banner img-responsive" id="testBanner12"></div></div>
					</div>			
				</div>						
			</div>
			<!-- Carousel controls -->
			<a href="#myCarousel" class="carousel-control left carousel-control-prev" data-slide="prev">
				<span class="glyphicon glyphicon-chevron-left"></span>
			</a>
			<a href="#myCarousel" class="carousel-control right carousel-control-next" data-slide="next">
				<span class="glyphicon glyphicon-chevron-right"></span>
			</a>
		</div>	
	</div>

	<br />

	<div class="container-fluid mob-carousel">
	  <div id="myCarousel1" class="carousel slide" data-ride="carousel"  data-interval="2500">
	    <!-- Indicators -->
	    <ol class="carousel-indicators">
	      <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
	      <li data-target="#myCarousel1" data-slide-to="1"></li>
	      <li data-target="#myCarousel1" data-slide-to="2"></li>
	      <li data-target="#myCarousel1" data-slide-to="3"></li>
	      <li data-target="#myCarousel1" data-slide-to="4"></li>
	      <li data-target="#myCarousel1" data-slide-to="5"></li>
	      <li data-target="#myCarousel1" data-slide-to="6"></li>
	      <li data-target="#myCarousel1" data-slide-to="7"></li>
	      <li data-target="#myCarousel1" data-slide-to="8"></li>
	      <li data-target="#myCarousel1" data-slide-to="9"></li>
	      <li data-target="#myCarousel1" data-slide-to="10"></li>
	      <li data-target="#myCarousel1" data-slide-to="11"></li>
	    </ol>

	    <!-- Wrapper for slides -->
	    <div class="carousel-inner">

	      <div class="item active">
    		  <div class="banner img-responsive" id="mobBanner1"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner2"></div>
	      </div>
	    
	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner3"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner4"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner5"></div>
	      </div>
	    
	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner6"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner7"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner8"></div>
	      </div>
	    
	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner9"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner10"></div>
	      </div>

	      <div class="item">
    		  <div class="banner img-responsive" id="mobBanner11"></div>
	      </div>
	    
	      <div class="item">
          <div class="banner img-responsive" id="mobBanner12"></div>
	      </div>
	  
	    </div>

	    <!-- Left and right controls -->
	    <a class="left carousel-control hidden-xs" href="#myCarousel1" data-slide="prev">
	      <span class="glyphicon glyphicon-chevron-left"></span>
	      <span class="sr-only">Previous</span>
	    </a>
	    <a class="right carousel-control hidden-xs" href="#myCarousel1" data-slide="next">
	      <span class="glyphicon glyphicon-chevron-right"></span>
	      <span class="sr-only">Next</span>
	    </a>
	  </div>
	</div>




  <!-- Section Heading -->
  <div class="container-fluid category-header">
    <div class="alert alert-warning sectionName">
        <span class=" glyphicon glyphicon-th" style="margin-right:10px">  </span>
        <strong> All Categories  </strong>
    </div>
  </div>
  <!-- Categoris grid container -->
  <div class="conatainer-fluid category-container">
    <div class="col-md-2 col-xs-3">
      <a href="/category/3">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/accesories.png">
      <p class="catgry-align1">Accessories</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/1">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/baby.png">
      <p>Kids</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/14">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/book.svg">
      <p>Books</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/5">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/clothing.svg">
      <p>Clothing</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/8">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/computer.svg">
      <p class="catgry-align2">Electronics</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/15">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/finance.svg">
      <p>Finance</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/11">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/food.svg">
      <p>Food</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/9">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/gift.svg">
      <p>Gifts</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
      <a href="/category/7">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/home.svg">
      <p class="catgry-align2">Household</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/4">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/shoes.svg">
      <p>Shoes</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/21">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/services.svg">
      <p>Services</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/2">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/health.png">
      <p class="catgry-align1">Health care</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/6">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/sports.svg">
      <p>Sports</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/17">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/pet.svg">
      <p>Pets</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/10">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/watch.png">
      <p>Watch</p>
      </div>
      </a>
    </div>
    <div class="col-md-2 col-xs-3">
        <a href="/category/12">
        <div class="catgry">
      <img class="img-catgry" src="assets/img/tours.svg">
      <p>Tourism</p>
      </div>
      </a>
    </div>
  </div>
  <!-- All Merchants Container -->
  <div class="container-fluid top-offer-container">
    <div class="alert alert-warning sectionName">
        <span class=" glyphicon glyphicon-th" style="margin-right:10px">  </span>
        <strong> All Merchants  </strong> 
        <input type="text" class="pull-right merchant-filter " [(ngModel)]="filterMerchantTxt" placeholder=" &#128269; Filter Merchants" />  
    </div>
  </div>

  <div class="container-fluid" *ngIf="campaignList">
    <div class="col-md-2 col-sm-4 col-xs-4 campaign-block" *ngFor="let list of campaignList.campaigns | filter: filterMerchantTxt">
        <div class="thumbnail">
          <a href="https://linksredirect.com/?pub_id=39075CL35269&source=linkkit&url={{list.url}}" target="_blank">
            <img [src]="list.image" class="img-responsive" [alt]="list.name">
            <div class="caption text-center">
              <p>{{list.name}}</p>
            </div>
          </a>
        </div>
      </div>  
  </div>

</div>