import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../data.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  campaignList: Object;
  offersList: Object;
  zeroDeals: Boolean = false;
  searchStoreTxt: String;
  selectedStoreObj: Object;
  constructor(private dataService: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // get all offers
    this.spinner.show();
    this.dataService.getOffers().subscribe(data => {
      this.offersList = data;
    });
    this.getCampaignList();
  }

  getCampaignList(): void {
    this.dataService.getCampaignList().subscribe(data => {
      this.campaignList = data;
      //console.log('data===>', this.campaignList);
      this.spinner.hide();
    });
  }

  selectedStore(list): void {
    //console.log(list);
    this.selectedStoreObj = list;
    this.zeroDeals = false;
    this.dataService.getOffersByCampaignId(list.id).subscribe(data => {
      this.offersList = data;
      //console.log('data===>', this.offersList);
      if (data['total_count'] === 0) {
        this.zeroDeals = true;
      }
    });
  }
  searchStoreLive(): void {
    if (this.searchStoreTxt.length > 3) {
      this.dataService.getStoreSearchResult(this.searchStoreTxt)
        .subscribe(data => {
          //console.log('Search Result:', data);
          this.campaignList = data;
      });
    }
    // tslint:disable-next-line: triple-equals
    if (this.searchStoreTxt.length == 0) {
      this.getCampaignList();
    }
  }
}
